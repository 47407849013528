*{margin: 0;padding: 0;}
@import url('https://fonts.googleapis.com/css2?family=Fugaz+One&display=swap');

.app-wrapper {
    margin: 0 auto;
    width: 1200px;
    display: grid;
    grid-template-areas:
    "h h"
    "s c";
    grid-template-rows: 60px 1fr;
    grid-template-columns: 2fr 10fr;
}
.app-wrapper-content{grid-area: c;}
.sidebar{
    grid-area: s;
    background: #d2d6e1;
}