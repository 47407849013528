.dialogs{
    display: grid;
    grid-template-columns: 2fr 10fr;

}
.dialogsItems{
    border-top: #ffffff;
    margin-left: 10px;
}
.dialog{
    color: #707b9d;
    text-decoration: none;
    font-family: 'Fugaz One', cursive;
    font-size: 20px;
    border-bottom: 3px solid #707b9d;
    display: block;
    text-transform: uppercase;
    line-height: 13px;
    padding-top: 15px;
    transition: 0.5s;
}

.dialog:hover, .active{
    color: #8f63ae;
    padding-left: 15px;
}

.messages{

}
.message{
    padding: 10px;
    border-radius: 3px;
    background: #e6eaf5;
    margin: 10px;
    color: #6b6b6b;
    overflow: hidden;
    width: 80%;
    display: flex;
    justify-content: flex-start;
}
.messageImage{
    display: block;
    width: 50px;
    height: 50px;

}
.messageContent{

}

.myMessage {
    flex-direction: row-reverse;
    background: #f0ebfb;
    margin-left: calc(20% - 20px);
    margin-left: -webkit-calc(20% - 20px);
}
.messagesArea {}
.newMessage {
    display: grid;
    grid-template-areas: "m b";
    grid-template-columns: 1fr 100px;
    grid-gap: 10px;
    padding: 10px;
}
.newMessageArea {
    grid-area: m;
}
.newMessageArea textarea {
    height: 60px ;
    width: 100%;
    border: 1px solid #eeeeee;
    border-radius: 3px;
    width: 100%;
}
.newMessageButton {
    grid-area: b;
}
.newMessageButton button {
    height: 40px;
    width: 80px;
    line-height: 40px;
    border:none;
    color:#ffffff;
    background: #d2d6e1;
    text-transform: uppercase;
    transition: 0.5s;
    border-radius: 3px;
}
.newMessageButton button:hover {
    background: #b66ee9;
}
.postMessage textarea{
    height: 60px ;
    width: 100%;
    border: 1px solid #eeeeee;
    border-radius: 3px;
}
