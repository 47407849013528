.friends {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.friend {
    text-align: center;
}
.image {
    border-radius: 50%;
    background: #c1c1c1;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.image img {
    width: 70%;
    height: 70%;
}
.name {
    color: white;
}