.futureHeader {
    background: url(https://image.freepik.com/free-vector/_88188-1.jpg) repeat;
    margin:10px;
}
h1 {
    background-color: #fff;
    overflow: hidden;
    display: inline-block;
    font-weight: bold;
    color: transparent;
    font-size: 100px;
    line-height: 82px;
    border-bottom: 1px solid #dddddd;
    font-style: italic;
}
.futureCalendar span, h1 span {
    background: url(https://image.freepik.com/free-vector/_88188-1.jpg) 0px 0px no-repeat;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    display: block;
    background-size: 100%;
}
.futureManImage{

}
.futureManImage img{
    width: 200px;
    margin: auto;
    display: block;
    margin-top: 20px;
}
.futureManSkils{}
.futureManSkils img {
    height: 50px;
    position: absolute;
    top: 0;
    right: calc(50% - 129px);
    border: 5px solid #dbd8dd;
    border-radius: 5px;
    background: #f7f7f7;
}
.futureManSkils img:nth-child(1) {top: 0;}
.futureManSkils img:nth-child(2) {top: 70px;}
.futureManSkils img:nth-child(3) {top: 140px;}
.futureManSkils img:nth-child(4) {top: 210px;}
.futureManSkils img:nth-child(5) {top: 280px;}
.futureManSkils img:nth-child(6) {top: 300px;}
.futureManSkils img:nth-child(7) {top: 360px;}
.futureManSkils img:nth-child(8) {top: 420px;}
.futureManSkils img:nth-child(9) {top: 480px;}
.futureCalendar{

}
.futureCalendar div{
    text-decoration: none;
    font-family: 'Fugaz One', cursive;
    font-size: 18px;
    border-bottom: 3px solid #d8e9ff;
    display: block;
    text-transform: uppercase;
    line-height: 10px;
    padding-top: 20px;
    transition: 0.5s;
}
.futureCalendar div span{
    float: right;
    line-height: 12px;
    font-size: 14px;
}
.futureGoals{}
.futureGoals div {
    color: #717171;
    border-bottom: 1px solid #eeeeee;
    text-decoration: none;
    font-family: 'Fugaz One', cursive;
    font-size: 18px;
    border-bottom: 3px solid #d8e9ff;
    display: block;
    text-transform: uppercase;
    line-height: 10px;
    padding-top: 20px;
    transition: 0.5s;
}
.twoCols{
    display: grid;
    grid-template-areas:
            "m n"
            "m d";
    grid-template-columns: 280px auto;
}
.futureMan{
    position: relative;
    grid-area: m;
}
.newGoal{
    grid-area: n;
}
.futureDiary{
    grid-area: d;
    display: grid;
    grid-template-columns: 150px auto;
    grid-gap: 15px;
}
.newGoal input {
    display: block;
    border: 1px solid #d0bcdd;
    border-radius: 3px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 40px;
    padding: 0 15px;
}
.newGoal textarea {
    border: 1px solid #d0bcdd;
    border-radius: 3px;
    padding: 15px;
}
.newGoal button {
    height: 40px;
    width: 80px;
    line-height: 40px;
    border:none;
    color:#ffffff;
    background: #d2d6e1;
    text-transform: uppercase;
    transition: 0.5s;
    border-radius: 3px;
    display: block;
    margin: auto;
}
.newGoal button:hover{
    background: #b66ee9;

}

.newGoal textarea, .newGoal input {
    width: 60%;
    margin: auto;
    margin-bottom: 15px;
    display: block;
}